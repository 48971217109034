import { GrantMessage, GrantMessageUpdateRequestV2 } from "types/grant_message";
import { GrantMessageFormParameters } from "./useGrantMessageContents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GrantMessageHtmlBuilder } from "app/process/grantMessageHtmlBuilder";
import { ActionRepository } from "utils/ActionsRepository";
import RestApi from "utils/RestApi";
import GrantMessageUpdateRequestGenerator from "app/process/grantMessageUpdater";
import ErrorMessage from "utils/ErrorMessage";
import GrantMessageFormValidator from "app/process/grantMessageFormValidator";

const generateGrantMessageUpdateRequest = (
  action_uuid: string,
  imagePath: string | null,
  grantMessageContents: GrantMessageFormParameters
) => {
  const generator = new GrantMessageUpdateRequestGenerator();
  const tmp = grantMessageContents;

  if (imagePath) {
    tmp.design.image.path = imagePath;
  }

  return generator.execute(action_uuid, tmp);
};

type GrantMessageEditEventParameters = {
  previewHtml: string;
  errorMessages: ErrorMessage[];
};

type GrantMessageEditEventCallbacks = {
  saveGrantMessage: (
    grantMessageFormParameters: GrantMessageFormParameters
  ) => Promise<void>;
  isValidGrantMessage: (params: GrantMessageFormParameters) => boolean;
};

const useGrantMessageEditEvents = (
  managementApi: RestApi,
  currentGrantMessage: GrantMessage,
  grantMessageContents: GrantMessageFormParameters,
  updateGrantMessage: (request: GrantMessageUpdateRequestV2) => Promise<void>
): [GrantMessageEditEventParameters, GrantMessageEditEventCallbacks] => {
  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const builder = useMemo(() => new GrantMessageHtmlBuilder(), []);

  // NOTE: 許諾メッセージ更新
  useEffect(() => {
    setPreviewHtml(builder.buildRelease(grantMessageContents));
  }, [builder, grantMessageContents]);

  const isValidGrantMessage = useCallback(
    (params: GrantMessageFormParameters): boolean => {
      const validator = new GrantMessageFormValidator();
      const errors = validator.run(params);
      setErrorMessages(errors);
      return errors.length === 0;
    },
    [setErrorMessages]
  );

  const saveGrantMessage = useCallback(
    async (params: GrantMessageFormParameters) => {
      return new Promise<void>((resolve) => {
        const actionRepository = new ActionRepository(managementApi);
        if (params.design.image.isNewFile && params.design.image.file) {
          actionRepository
            .sendImage(params.design.image.file)
            .then((imagePath: string) => {
              updateGrantMessage(
                generateGrantMessageUpdateRequest(
                  currentGrantMessage.grantMessage.action_uuid,
                  imagePath,
                  params
                )
              ).then(resolve);
            });
        } else {
          updateGrantMessage(
            generateGrantMessageUpdateRequest(
              currentGrantMessage.grantMessage.action_uuid,
              null,
              params
            )
          ).then(resolve);
        }
      });
    },
    [
      managementApi,
      currentGrantMessage.grantMessage.action_uuid,
      updateGrantMessage,
    ]
  );

  return [
    {
      previewHtml,
      errorMessages,
    },
    { saveGrantMessage, isValidGrantMessage },
  ];
};

export default useGrantMessageEditEvents;
