import { ACTION_TYPES, ActionTypes } from "interfaces/models";
import { GRANT_MESSAGE_LAYOUT_TYPE } from "interfaces/v2/grantMessageContents";

export class ActionTypeTranslator {
  toGrantMessageLayoutType(actionType: ActionTypes) {
    switch (actionType) {
      case ACTION_TYPES.FULL_MODAL:
        return GRANT_MESSAGE_LAYOUT_TYPE.MODAL;
      case ACTION_TYPES.BOTTOM_MODAL:
        return GRANT_MESSAGE_LAYOUT_TYPE.POP_UP;
      case ACTION_TYPES.TOGGLE_MODAL:
      default:
        throw new EvalError(`UNSUPPORTED_ACTION_TYPE: ${actionType}`);
    }
  }
}
