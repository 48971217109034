import React, { useCallback, useMemo } from "react";

import { ActionTypeTranslator } from "app/process/actionTypeTranslator";
import { GrantMessageType, GrantMessageV2 } from "types/grant_message";
import { unixTimeToDateTimeSecond } from "utils/format/unixtime";

import fontStyles from "fontStyles.module.scss";
import animeStyles from "animation.module.scss";

import editIconV3 from "images/icons/icon_edit_v3.svg";
import browserGrantImage from "images/browser_grant.png";

import LoadingIcon from "atoms/LoadingIcon";
import ToolTip from "atoms/accountPagesShared/ToolTip";
import CopyableText from "atoms/ezPushShared/accountPageShared/CopyableText";
import MultipleLineText from "atoms/ezPushShared/accountPageShared/MultipleLineText";

import ActionHtmlPreviewer from "molecules/v2/ActionHtmlPreviewer";

import LoadingView from "organisms/account/LoadingView";
import HorizontalIconLayout from "templates/ezPush/HorizontalIconLayout";
import HorizontalLayout from "templates/ezPush/HorizontalLayout";
import MiniPanel from "templates/ezPush/MiniPanel";
import GrantMessageTileLayout from "templates/ezPush/grantMessage/GrantMessageTileLayout";
import { useHistory } from "react-router-dom";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import CenteringLayout from "templates/ezPush/CenteringLayout";
import OrangeBorderedButton from "atoms/ezPushShared/accountPageShared/OrangeBorderedButton";
import UnorderedList from "atoms/ezPushShared/UnorderedList";
import VerticalSpreadLayout from "templates/ezPush/VerticalSpreadLayout";
import AsteriskList from "atoms/ezPushShared/AsteriskList";
import GrantMessageControlPanelLayout from "templates/ezPush/grantMessage/GrantMessageControlPanelLayout";

const GrantMessageDescription: React.VFC<{
  grantMessageType: GrantMessageType | undefined;
}> = ({ grantMessageType }) => {
  const description = ((grantMessageType: GrantMessageType | undefined) => {
    if (grantMessageType === "browser_default") {
      return {
        items: [
          "1クリックで許諾を得られます。",
          "許諾ユーザーが集まりやすく、オススメの方法です。",
          "テキストやデザインを指定することはできません。(※)",
        ],
        note: "ブラウザ標準デザインのテキストやデザインは、配信先のブラウザやデバイスによって異なります。",
      };
    } else if (grantMessageType === "custom") {
      return {
        items: [
          "２クリックで許諾を得る必要があります。(※)",
          <div>
            <div>ブラウザ標準デザインと比べ、許諾率が</div>
            <div>低下する可能性があります。</div>
          </div>,
          "テキストや画像、色などを変更することができます。",
        ],
        note: "許諾を取得するにはカスタムデザインのボタンをクリックし、その後表示されるブラウザ標準メッセージの許可ボタンをクリックする必要があります。",
      };
    } else {
      return undefined;
    }
  })(grantMessageType);

  if (description) {
    return (
      <VerticalSpreadLayout
        items={[
          <UnorderedList
            items={description.items.map((text) => (
              <div className={fontStyles.largeText}>{text}</div>
            ))}
          />,
          <AsteriskList
            items={[
              <span className={fontStyles.text} style={{ color: "#666666" }}>
                {description.note}
              </span>,
            ]}
          />,
        ]}
      />
    );
  } else {
    return <></>;
  }
};

const GrantMessageDesignPreviewer: React.VFC<{
  grantMessage: GrantMessageV2 | undefined;
}> = ({ grantMessage }) => {
  if (grantMessage === undefined) {
    return <LoadingView iconElement={<LoadingIcon />} />;
  } else if (
    grantMessage.grantMessage.grant_message_type === "browser_default"
  ) {
    return (
      <CenteringLayout
        element={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgb(243,243,243)",
              padding: "10px",
            }}
          >
            <img
              width="325px"
              src={browserGrantImage}
              alt="ブラウザ標準デザイン"
            />
          </div>
        }
      />
    );
  } else if (grantMessage.grantMessage.grant_message_type === "custom") {
    return (
      <ActionHtmlPreviewer
        layoutType={new ActionTypeTranslator().toGrantMessageLayoutType(
          grantMessage.action.action_type
        )}
        html={grantMessage.grantMessage.design_html}
      />
    );
  } else {
    return <LoadingView iconElement={<LoadingIcon />} />;
  }
};

const GrantMessagePanel: React.VFC<{
  grantMessage: GrantMessageV2;
}> = ({ grantMessage }) => {
  // NOTE: functions
  const history = useHistory();
  const goGrantMessageEdit = useCallback(
    () => history.push(V2_ROUTING_TABLE.ACCOUNT.GRANT_MESSAGE.EDIT),
    [history]
  );

  // NOTE: elements
  const previewElement = useMemo(
    () => (
      <MiniPanel
        titleElement={<span className={fontStyles.boldText}>プレビュー</span>}
        mainElement={
          <GrantMessageDesignPreviewer grantMessage={grantMessage} />
        }
      />
    ),
    [grantMessage]
  );
  const testParameterElement = useMemo(
    () =>
      grantMessage ? (
        <CopyableText
          text={`?ezcx_action=${grantMessage.grantMessage.action_uuid}`}
        />
      ) : (
        <LoadingIcon />
      ),
    [grantMessage]
  );

  const buttonElement = useMemo(() => {
    return (
      <HorizontalLayout
        elements={[
          <OrangeBorderedButton
            text={
              <HorizontalIconLayout
                elements={[
                  <img
                    src={editIconV3}
                    style={{ verticalAlign: "middle" }}
                    alt=""
                  />,
                  <span>カスタムデザインを編集</span>,
                ]}
              />
            }
            onClick={goGrantMessageEdit}
          />,
          grantMessage ? (
            <span className={fontStyles.note}>
              最終更新日時：
              {unixTimeToDateTimeSecond(grantMessage.grantMessage.updated_at)}
            </span>
          ) : (
            <LoadingIcon />
          ),
        ]}
      />
    );
  }, [goGrantMessageEdit, grantMessage]);

  const controlElement = useMemo(
    () => (
      <MiniPanel
        mainElement={
          <GrantMessageControlPanelLayout
            titleElement={
              <HorizontalIconLayout
                elements={[
                  <span className={fontStyles.boldText}>
                    表示確認用パラメータ
                  </span>,
                  <ToolTip
                    id="displayConfirmation"
                    text={
                      <MultipleLineText
                        texts={[
                          "許諾メッセージのデザインをサイト上で確認",
                          "するには、URLの末尾にこのパラメータを",
                          "追加してください。",
                        ]}
                      />
                    }
                  />,
                ]}
              />
            }
            testParemeterElement={testParameterElement}
            editButtonElement={
              grantMessage.grantMessage.grant_message_type === "custom"
                ? buttonElement
                : undefined
            }
          />
        }
      />
    ),
    [grantMessage, buttonElement, testParameterElement]
  );

  return (
    <div className={animeStyles.fadeIn}>
      <GrantMessageTileLayout
        describeElement={
          <GrantMessageDescription
            grantMessageType={grantMessage.grantMessage.grant_message_type}
          />
        }
        previewElement={previewElement}
        controlElement={controlElement}
      />
    </div>
  );
};

export default GrantMessagePanel;
