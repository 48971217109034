import { GrantMessageFormParameters } from "app/hooks/v2/grantMessage/useGrantMessageContents";
import { GrantMessageLayoutHtmlBuilder } from "./grantMessageLayoutHtmlBuilder";
import { GrantMessageStyleHtmlBuilder } from "./grantMessageStyleHtmlBuilder";

export class GrantMessageHtmlBuilder {
  layoutBuilder: GrantMessageLayoutHtmlBuilder;
  styleBuilder: GrantMessageStyleHtmlBuilder;

  constructor() {
    this.layoutBuilder = new GrantMessageLayoutHtmlBuilder();
    this.styleBuilder = new GrantMessageStyleHtmlBuilder();
  }
  buildRelease(data: GrantMessageFormParameters): string {
    return (
      this.styleBuilder.execute(data) +
      "\n" +
      this.layoutBuilder.execute(data)
    );
  }
}
