import { useState } from "react";

import { AccountPageContextV2 } from "interfaces/v2/accountPageContextV2";

import AccountPageBase from "./AccountPageBase";
import LoadingIcon from "atoms/LoadingIcon";
import LoadingView from "organisms/account/LoadingView";
import GrantMessageEditor from "organisms/ezPush/grantMessageEdit/GrantMessageEditor";
import {
  GrantMessageUpdateRequestV2,
  GrantMessageV2,
} from "types/grant_message";

const GrantMessageEditPage: React.VFC<{
  accountContext: AccountPageContextV2;
  grantMessage: GrantMessageV2 | undefined;
  updateGrangMessage: (request: GrantMessageUpdateRequestV2) => Promise<void>;
  loadGrantMessages: () => void;
}> = ({
  accountContext,
  grantMessage,
  updateGrangMessage,
  loadGrantMessages,
}) => {
  const [modalElement, setModalElement] = useState<JSX.Element | undefined>();

  return (
    <AccountPageBase
      context={accountContext}
      areaContents={
        grantMessage ? (
          <GrantMessageEditor
            accountContext={accountContext}
            grantMessage={grantMessage}
            updateGrantMessage={updateGrangMessage}
            loadGrantMessages={loadGrantMessages}
            setModalElement={setModalElement}
          />
        ) : (
          <LoadingView
            iconElement={<LoadingIcon />}
            textElement={<div>データ読み込み中．．．</div>}
          />
        )
      }
      modalContents={modalElement}
    />
  );
};

export default GrantMessageEditPage;
