import { GrantMessageFormParameters } from "app/hooks/v2/grantMessage/useGrantMessageContents";
import { ACTION_TYPES } from "interfaces/models";
import {
  GRANT_MESSAGE_LAYOUT_TYPE,
  GrantMessageLayoutType,
} from "interfaces/v2/grantMessageContents";
import { GrantMessageUpdateRequestV2 } from "types/grant_message";
import { GrantMessageHtmlBuilder } from "./grantMessageHtmlBuilder";

class GrantMessageUpdateRequestGenerator {
  getActionType(type: GrantMessageLayoutType): string {
    if (type === GRANT_MESSAGE_LAYOUT_TYPE.POP_UP) {
      return ACTION_TYPES.BOTTOM_MODAL;
    } else if (type === GRANT_MESSAGE_LAYOUT_TYPE.MODAL) {
      return ACTION_TYPES.FULL_MODAL;
    }

    throw new Error(`INVALID_LAYOUT_SELECTED: ${type}`);
  }

  execute(
    action_uuid: string,
    data: GrantMessageFormParameters
  ): GrantMessageUpdateRequestV2 {
    const builder = new GrantMessageHtmlBuilder();

    return {
      action_uuid: action_uuid,
      grant_message_type: "custom",
      contents: {
        // NOTE: design_html is built for rendering for the frontend removed javascript code.
        // FIXME: no need to design_html, message_html is enough.
        design_html: builder.buildRelease(data),
        // NOTE: message_html is built for the instance for rendering to endusers.
        message_html: builder.buildRelease(data),
        form_input: JSON.stringify(data),
        display_type: this.getActionType(data.layout.layoutType),
      },
    };
  }
}

export default GrantMessageUpdateRequestGenerator;
