import { GrantMessageFormParameters } from "app/hooks/v2/grantMessage/useGrantMessageContents";
import { GrantMessageContentsLayoutParameters } from "app/hooks/v2/grantMessage/useGrantMessageContentsLayout";
import {
  GRANT_MESSAGE_LAYOUT_TYPE,
  GRANT_MESSAGE_MODAL_DISPLAY_POSITION,
  GRANT_MESSAGE_POP_UP_DISPLAY_POSITION,
  GrantMessageDisplayPosition,
  GrantMessageLayoutType,
} from "interfaces/v2/grantMessageContents";
import {
  COLOR_TYPE,
  ColorType,
} from "molecules/GradationColorPicker/ColorTypeSelector";
import {
  COMMON_STYLE_PARAMETERS,
  CREATIVE_PARTS_CLASS_NAME,
} from "utils/action_creation/creative";

export class GrantMessageStyleHtmlBuilder {
  DEFAULT_POSITION_MARGIN = 5;
  createStyleParameters(data: GrantMessageFormParameters) {
    const modalStyleElements = [
      `--border-color: ${data.design.frame.lineColor};`,
      `--background-color: ${data.design.frame.backgroundColor};`,
      `--modal-border-size: ${data.design.frame.lineWidth}px;`,
    ];

    const headlineStyleElements = [
      `--headline-font-color: ${data.design.title.fontColor};`,
      `--headline-font-size: ${data.design.title.fontSize}px;`,
    ];

    const articleStyleElements = [
      `--article-color: ${data.design.description.fontColor};`,
      `--article-font-size: ${data.design.description.fontSize}px;`,
    ];

    const buttonBackgroundColor =
      data.design.button.colorType === COLOR_TYPE.SIMPLE
        ? data.design.button.backgroundColor[0]
        : `linear-gradient(${data.design.button.backgroundColor.join(",")})`;

    const buttonPadding: string[] = [
      `${data.design.button.heightMargin + 1}px`,
      `${data.design.button.widthMargin}px`,
      `${data.design.button.heightMargin}px`,
      `${data.design.button.widthMargin}px`,
    ];

    const buttonStyleElements = [
      `--button-background-color: ${buttonBackgroundColor};`,
      `--button-font-color: ${data.design.button.fontColor};`,
      `--button-font-size: ${data.design.button.fontSize}px;`,
      `--button-border-radius: ${data.design.button.borderRadius}px;`,
      `--button-padding: ${buttonPadding.join(" ")};`,
    ];

    const closeButtonStyleElements = [
      `--close-button-color: ${data.design.closeButton.crossColor};`,
      `--close-button-background-color: ${data.design.closeButton.backgroundColor};`,
    ];

    const imageStyleElements = [
      `--small-image-size: ${30 * data.design.image.magnification}%;`,
      `--small-image-text-area-size: 70%;`,
    ];

    return `:root {
      /* base */
      ${modalStyleElements.join("\n")}

      /* headline */
      ${headlineStyleElements.join("\n")}

      /* article */
      ${articleStyleElements.join("\n")}

      /* button */
      ${buttonStyleElements.join("\n")}

      /* close button */
      ${closeButtonStyleElements.join("\n")}

      /* image */
      ${imageStyleElements.join("\n")}
    }`;
  }

  generatePopUpCSSPositionParameters(
    displayPosition: GrantMessageDisplayPosition,
    topMargin: number,
    bottomMargin: number
  ): string {
    let styles: string[] = [];

    // NOTE: vertical setting
    switch (displayPosition) {
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_BOTTOM:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_BOTTOM:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_BOTTOM: {
        styles = styles.concat([
          "top: auto;",
          `bottom: ${this.DEFAULT_POSITION_MARGIN + bottomMargin}px;`,
        ]);
        break;
      }
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_TOP:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_TOP:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_TOP: {
        styles = styles.concat([
          `top: ${this.DEFAULT_POSITION_MARGIN + topMargin}px;`,
          `bottom: auto;`,
        ]);
        break;
      }
      case GRANT_MESSAGE_MODAL_DISPLAY_POSITION.CENTER:
      default: {
        break;
      }
    }

    // NOTE: horizontal setting
    switch (displayPosition) {
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_TOP:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.CENTER_BOTTOM: {
        styles = styles.concat([
          "left: 50%;",
          "right: auto;",
          "transform: translateX(-50%);",
        ]);
        break;
      }
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_TOP:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.LEFT_BOTTOM: {
        styles = styles.concat(["left: 5px;", "right: auto;"]);
        break;
      }
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_TOP:
      case GRANT_MESSAGE_POP_UP_DISPLAY_POSITION.RIGHT_BOTTOM: {
        styles = styles.concat(["left: auto;", "right: 5px;"]);
        break;
      }
      case GRANT_MESSAGE_MODAL_DISPLAY_POSITION.CENTER:
      default: {
        break;
      }
    }

    return styles.join(" ");
  }

  createPositionParameters(data: GrantMessageContentsLayoutParameters) {
    const settings: string[] = [
      "position: fixed;",
      "overflow: hidden;",
      "z-index: 99999;",
      "box-sizing: border-box;",
      "overflow-wrap: break-word;",
    ];

    switch (data.layoutType) {
      case GRANT_MESSAGE_LAYOUT_TYPE.POP_UP:
        const popUpSettings: string[] = [
          "padding: 10px;",
          this.generatePopUpCSSPositionParameters(
            data.displayPosition,
            data.topMargin,
            data.bottomMargin
          ),
        ];

        return `${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
          CREATIVE_PARTS_CLASS_NAME.BOTTOM_MODAL
        } {
      ${settings.join("\n")}
      ${popUpSettings.join("\n")}
    }`;
      case GRANT_MESSAGE_LAYOUT_TYPE.MODAL:
        const modalSettings: string[] = [
          "background: rgba(102, 102, 102, .5);",
          "top: 0;",
          "bottom: 0;",
          "right: 0;",
          "left: 0;",
        ];

        return `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
          CREATIVE_PARTS_CLASS_NAME.FULL_MODAL
        } {
      ${settings.join("\n")}
      ${modalSettings.join("\n")}
    }`;
      default:
        console.error(`Invalid contents type ${data.layoutType}`);
        return "";
    }
  }

  createContentsBaseStyle() {
    const scrollBarStyle = `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar {
      width: 12px;
  }`;

    const scrollBarTrackStyle = `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
  }`;

    const scrollBarThumbStyle = `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: content-box;
  }`;

    const contentsBaseStyleElements: string[] = [
      "background: var(--background-color);",
      "border: var(--modal-border-size) solid var(--border-color);",
      "border-radius: 5px;",
      "display: flex;",
      "flex-direction: column;",
      "justify-content: center;",
      "align-items: center;",
      "box-sizing: border-box;",
      "width: 325px;",
      "height: auto;",
      "padding: 10px;",
      "box-shadow: 0 0 10px rgba(0, 0, 0, .16);",
      "font-size: 0px;",
      "text-align: center;",
      "overflow-x: hidden;",
      "overflow-y: auto;",
      "scrollbar-width: thin;",
    ];

    const contentsBaseStyle = `
  ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE
    } {
    ${contentsBaseStyleElements.join("\n")}
  }`;

    return `
  ${contentsBaseStyle}
  ${scrollBarStyle}
  ${scrollBarTrackStyle}
  ${scrollBarThumbStyle}`;
  }

  createHeadlineStyle(isImage: boolean) {
    const headlineStyleElements: string[] = [
      "color: var(--headline-font-color);",
      "font-size: var(--headline-font-size);",
      "font-weight: 700;",
      "margin: 0;",
      `text-align: ${isImage ? "left" : "center"};`,
      "line-height: 1.2;",
    ];

    return `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.IMAGE
    } + .${CREATIVE_PARTS_CLASS_NAME.HEADLINE}{
      margin-top:8px;
    }
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.HEADLINE
    } {
      ${headlineStyleElements.join("\n")}
    }`;
  }

  createArticleStyle(isImage: boolean) {
    const articleOffset = `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} + .${CREATIVE_PARTS_CLASS_NAME.ARTICLE},
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.ARTICLE}{
      margin-top:8px;
    }`;

    const articleStyleElements: string[] = [
      "color: var(--article-color);",
      "font-size: var(--article-font-size);",
      "margin: 0;",
      `text-align: ${isImage ? "left" : "center"};`,
      "line-height: 1.2;",
    ];

    const articleStyle = `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.ARTICLE
    } {
      ${articleStyleElements.join("\n")}
    }`;

    return `
    ${articleOffset}
    ${articleStyle}`;
  }

  createButtonStyle(type: ColorType) {
    const backgroundColorEntity =
      type === COLOR_TYPE.SIMPLE ? "background-color" : "background";

    const buttonStyleElements: string[] = [
      `${backgroundColorEntity}: var(--button-background-color);`,
      "font-size: var(--button-font-size);",
      "color: var(--button-font-color);",
      "padding: var(--button-padding);",
      "border-radius: var(--button-border-radius);",
      "box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);",
      "box-sizing: border-box;",
      "display: inline-block;",
      "text-decoration: none;",
      "font-weight: 700;",
      "border: none;",
      "cursor: pointer;",
      "margin: 8px 0 0 0;",
      "max-width: 100%;",
      "line-height: 1.2;",
    ];

    return `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.BUTTON
    } {
      ${buttonStyleElements.join("\n")}
    }
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.BUTTON
    }:hover {
      opacity: 0.7;
    }`;
  }

  createFixedAreaStyle = (type: GrantMessageLayoutType) => {
    let fixedAreaElements = ["position: absolute;", "top: 0px;", "right: 0px;"];

    if (type === GRANT_MESSAGE_LAYOUT_TYPE.MODAL) {
      fixedAreaElements.push("transform: translate(50%, -50%);");
    }

    return `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.FIXED_AREA
    } {
      ${fixedAreaElements.join("\n")}
    }`;
  };

  createCloseStyle() {
    const closeButtonStyleElements: string[] = [
      "width: 11px;",
      "height: 11px;",
      "padding: 4px;",
      "border-radius: 11px;",
      "background-color: var(--close-button-background-color);",
      "display: block;",
      "line-height: 0;",
      "cursor: pointer;",
      "transition: opacity .3s ease;",
      "box-sizing: content-box;",
    ];
    return `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CLOSE} {
      ${closeButtonStyleElements.join("\n")}
    }
  
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.CLOSE
    } path {
      fill: var(--close-button-color);
    }
  
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${
      CREATIVE_PARTS_CLASS_NAME.CLOSE
    }:hover {
      opacity: 0.7;
    }`;
  }

  createImageStyle = () => {
    const imageContainerElement = `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.ARTICLE} + .${CREATIVE_PARTS_CLASS_NAME.CONTAINER},
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.HEADLINE} + .${CREATIVE_PARTS_CLASS_NAME.CONTAINER}{
      margin-top:8px;
    }`;

    const imageFrameElement = `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME} {
      width: var(--small-image-size);
      margin-right: 10px;
    }`;

    const imageElement = `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.IMAGE} {
      width: 100%;
    }`;

    return `
    ${imageContainerElement}
    ${imageFrameElement}
    ${imageElement}`;
  };

  createContainerStyle(isImage: boolean) {
    return isImage
      ? `
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTAINER} {
      display: flex;
      align-items: center;
    }
  
    ${COMMON_STYLE_PARAMETERS.CREATIVE_ID} .${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT} {
      width: var(--small-image-text-area-size);
    }`
      : "";
  }

  execute(data: GrantMessageFormParameters): string {
    let items = [];
    items.push(this.createStyleParameters(data));

    items.push(this.createContentsBaseStyle());

    items.push(this.createPositionParameters(data.layout));

    const isImage = !!data.design.image.path;

    items.push(this.createContainerStyle(isImage));
    items.push(this.createImageStyle());
    items.push(this.createHeadlineStyle(isImage));
    items.push(this.createArticleStyle(isImage));
    items.push(this.createButtonStyle(data.design.button.colorType));
    items.push(this.createFixedAreaStyle(data.layout.layoutType));
    items.push(this.createCloseStyle());

    return `<style>
      ${items.map((e) => e).join("\n")}
  </style>`;
  }
}
