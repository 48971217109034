import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import RestApi from "utils/RestApi";
import { V2_ROUTING_TABLE } from "app/system_defaults/v2_routing";
import useAccountPageContext from "app/hooks/v2/useAccountContext";
import HomePage from "pages/ezPush/account/HomePage";
import PushNotificationPage from "pages/ezPush/account/PushNotificationPage";
import { useSubscriptionStatistics } from "app/hooks/v2/useSubscriptionStatistics";
import PushNotificationEditPage from "pages/ezPush/account/PushNotificationEditPage";
import DeliveryReportPage from "pages/ezPush/account/DeliveryReportPage";
import SegmentationRuleListPage from "pages/ezPush/account/SegmentationRuleListPage";
import SegmentationRuleEditPage from "pages/ezPush/account/SegmentationRuleEditPage";
import GrantMessageListPage from "pages/ezPush/account/GrantMessageListPage";
import useGrantMessageV2 from "app/hooks/v2/useGrantMessageV2";
import GrantMessageEditPage from "pages/ezPush/account/GrantMessageEditPage";
import AdminDeliveryReportPage from "pages/ezPush/account/AdminDeliveryReportPage";
import BasicConfigurationPage from "pages/ezPush/account/BasicConfigurationPage";
import MeasurementUrlSettingEditPage from "pages/ezPush/account/MeasurementUrlSettingEditPage";
import { useLayoutEffect } from "react";
import SubscribeDomainRegisterPage from "pages/ezPush/account/SubscribeDomainRegisterPage";
import useDomain from "app/hooks/v2/useDomain";

type AccountPageGroupRoutingV2Props = {
  managementApi: RestApi;
  loginAccountId: string;
  onSignOut: () => void;
  onSystemError: () => void;
};

const AccountPageGroupRoutingV2 = ({
  managementApi,
  loginAccountId,
  onSignOut,
  onSystemError,
}: AccountPageGroupRoutingV2Props) => {
  const history = useHistory();

  const accountContext = useAccountPageContext(
    managementApi,
    loginAccountId,
    onSignOut,
    onSystemError
  );

  const [grantMessages, grantMessageV2Callbacks] = useGrantMessageV2(
    accountContext.managementApi
  );

  const statsParameters = useSubscriptionStatistics(
    accountContext.managementApi
  );

  const [domains] = useDomain(accountContext.managementApi);

  useLayoutEffect(() => {
    if (domains?.length === 0) {
      history.push(V2_ROUTING_TABLE.ACCOUNT.INITIALIZE.SUBSCRIBE_DOMAIN);
    }
  }, [domains, history]);

  return (
    <Switch>
      <Route exact path={V2_ROUTING_TABLE.ROOT}>
        <Redirect to={V2_ROUTING_TABLE.ACCOUNT.HOME} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.HOME}>
        <HomePage
          accountContext={accountContext}
          grantMessage={
            grantMessages
              ? grantMessages.filter(
                  (gm) =>
                    gm.grantMessage.grant_message_type ===
                    gm.grantMessageSetting.selected_message_type
                )[0]
              : undefined
          }
          statsParameters={statsParameters}
          loadGrantMessage={grantMessageV2Callbacks.load}
        />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.GRANT_MESSAGE.HOME}>
        <GrantMessageListPage
          accountContext={accountContext}
          grantMessages={grantMessages}
          grantMessageCallbacks={grantMessageV2Callbacks}
        />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.GRANT_MESSAGE.EDIT}>
        <GrantMessageEditPage
          accountContext={accountContext}
          grantMessage={
            grantMessages
              ? grantMessages.filter(
                  (gm) => gm.grantMessage.grant_message_type === "custom"
                )[0]
              : undefined
          }
          updateGrangMessage={grantMessageV2Callbacks.update}
          loadGrantMessages={grantMessageV2Callbacks.load}
        />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.HOME}>
        <PushNotificationPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.PUSH_NOTIFICATION.EDIT}>
        <PushNotificationEditPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.REPORT.HOME}>
        <DeliveryReportPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.HOME}>
        <SegmentationRuleListPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.SEGMENTATION_RULE.EDIT}>
        <SegmentationRuleEditPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.ADMIN_DELIVERY_REPORT.HOME}>
        <AdminDeliveryReportPage accountContext={accountContext} />
      </Route>
      {/* NOTE: このリダイレクトによって、side barを光らせることができる。暗黙の依存が発生している */}
      {/* FIXME: ルーティングを整理してください */}
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.ROOT}>
        <Redirect to={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.HOME} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.HOME}>
        <BasicConfigurationPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.DOMAIN}>
        <BasicConfigurationPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.FILE}>
        <BasicConfigurationPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.HOME}>
        <BasicConfigurationPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.BASIC_CONFIGURATION.URL.EDIT}>
        <MeasurementUrlSettingEditPage accountContext={accountContext} />
      </Route>
      <Route exact path={V2_ROUTING_TABLE.ACCOUNT.INITIALIZE.SUBSCRIBE_DOMAIN}>
        <SubscribeDomainRegisterPage accountContext={accountContext} />
      </Route>
      <Route>
        <Redirect to={V2_ROUTING_TABLE.AUTH.LOGIN} />
      </Route>
    </Switch>
  );
};

export default AccountPageGroupRoutingV2;
