export class HtmlSanitizer {
  replaceSpecialCharacters(value: string): string {
    const replaceList: { [key: string]: string } = {
      "&": "&amp;",
      "'": "&#x27;",
      "`": "&#x60;",
      '"': "&quot;",
      "<": "&lt;",
      ">": "&gt;",
      "\n": "<br/>",
    };

    return value.replace(/[&'`"<>\n]/g, (match) => replaceList[match] || match);
  }
}
