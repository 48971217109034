import { GrantMessageFormParameters } from "app/hooks/v2/grantMessage/useGrantMessageContents";
import {
  CREATIVE_ACTION_CLASS_NAME,
  CREATIVE_PARTS_CLASS_NAME,
} from "utils/action_creation/creative";
import { HtmlSanitizer } from "./htmlSanitizer";

export class GrantMessageLayoutHtmlBuilder {
  createImageParts(imageUrl: string): string {
    return `<div class="${CREATIVE_PARTS_CLASS_NAME.IMAGE_FRAME}">
          <img class="${CREATIVE_PARTS_CLASS_NAME.IMAGE}" src="${imageUrl}" alt="" />
        </div>`;
  }

  createHeadlineParts(headlineText: string) {
    return `<div class="${CREATIVE_PARTS_CLASS_NAME.HEADLINE}">${headlineText}</div>`;
  }

  createArticleParts(article: string) {
    return `<div class="${CREATIVE_PARTS_CLASS_NAME.ARTICLE}">${article}</div>`;
  }

  createButton(buttonText: string) {
    return `<div>
      <a id="${CREATIVE_ACTION_CLASS_NAME.REGISTER}" class="${CREATIVE_PARTS_CLASS_NAME.BUTTON} ${CREATIVE_ACTION_CLASS_NAME.REACTION}">
        ${buttonText}
      </a>
    </div>`;
  }

  createCloseButton() {
    return `<a class="${CREATIVE_PARTS_CLASS_NAME.CLOSE} ${CREATIVE_ACTION_CLASS_NAME.CLOSE}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
        <path id="path_7848" data-name="path_7848"
          d="M473.114,104.639l-4.339-4.349,4.368-4.413-.029-.192-.918-.88-.194.028-4.338,4.344-4.407-4.373-.193.028-.849.851a.187.187,0,0,0,0,.262l4.338,4.344-4.342,4.352a.184.184,0,0,0,0,.258l.918.879.194-.028,4.337-4.343L472,105.75l.087.055h.063l.1-.04.862-.864A.208.208,0,0,0,473.114,104.639Z"
          transform="translate(-462.16 -94.805)" fill="#444" />
      </svg>
    </a>`;
  }

  execute(data: GrantMessageFormParameters): string {
    const sanitizer = new HtmlSanitizer();
    const imageElement = data.design.image.path
      ? this.createImageParts(
          sanitizer.replaceSpecialCharacters(data.design.image.path)
        )
      : "";
    const titleElement = data.design.title.text
      ? this.createHeadlineParts(
          sanitizer.replaceSpecialCharacters(data.design.title.text)
        )
      : "";
    const articleElement = data.design.description.text
      ? this.createArticleParts(
          sanitizer.replaceSpecialCharacters(data.design.description.text)
        )
      : "";
    const buttonElement = data.design.button.text
      ? this.createButton(
          sanitizer.replaceSpecialCharacters(data.design.button.text)
        )
      : "";
    const closeButtonElement = this.createCloseButton();

    return `
  <div class="${CREATIVE_PARTS_CLASS_NAME.CONTENTS_BASE}">
    <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER}">
      ${imageElement}
      <div class="${CREATIVE_PARTS_CLASS_NAME.CONTAINER_TEXT}">
        ${titleElement}
        ${articleElement}
      </div>
    </div>
    ${buttonElement}
  </div>
  <div class="${CREATIVE_PARTS_CLASS_NAME.FIXED_AREA}">${closeButtonElement}</div>`;
  }
}
